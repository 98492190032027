
#adobe-dc-view{
  width: 80vw;
  height: 60vw;
  max-width: 900px;
  max-height: 675px;
}
@media screen and (max-width: 960px) {
#adobe-dc-view{
    width: 92vw;
    height: 69vw;
}
}
